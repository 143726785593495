import React, { memo, useState } from 'react';

import CustomerCard from '~components/card/customer';
import ProfileModal from '~containers/profile/modal/customer';

import CustomerCardMobile from '~components/card/customer/indexMobile';
import { useIsMobile } from '~hooks/common';


const ResultsItem = memo(
  ({
    personId,
    locationId,
    interval,
    duration,
    location,
    days,
    score,
    firstName,
    profilePicture,
    verification,
    lastLogin,
    isOnline,
    registerDate,
    personalNote,
    showFavorite,
    isFavorite,
    published,
    showCloseButton,
    onClose = () => {},
  }) => {
    const [isProfileModalOpened, setIsProfileModalOpened] = useState(false);
    const isMobile = useIsMobile();

    return (
      <>
        {isMobile ? (
          <CustomerCardMobile
            key={personId}
            personId={personId}
            locationId={locationId}
            interval={interval}
            duration={duration}
            days={days}
            address={location}
            firstName={firstName}
            profilePicture={profilePicture}
            verification={verification}
            personalNote={personalNote}
            showViewButton
            onView={setIsProfileModalOpened}
            showFavorite={showFavorite}
            isFavorite={isFavorite}
            published={published}
            showCloseButton={showCloseButton}
            onClose={onClose}
            lastLogin={lastLogin}
            isOnline={isOnline}
          />
        ) : (
          <CustomerCard
            key={personId}
            personId={personId}
            locationId={locationId}
            interval={interval}
            duration={duration}
            days={days}
            address={location}
            firstName={firstName}
            profilePicture={profilePicture}
            verification={verification}
            personalNote={personalNote}
            showViewButton
            onView={setIsProfileModalOpened}
            showFavorite={showFavorite}
            isFavorite={isFavorite}
            published={published}
            showCloseButton={showCloseButton}
            onClose={onClose}
            lastLogin={lastLogin}
            isOnline={isOnline}
          />
        )}
        
        <ProfileModal
          personId={personId}
          locationId={locationId}
          location={location}
          score={score}
          interval={interval}
          duration={duration}
          days={days}
          firstName={firstName}
          profilePicture={profilePicture}
          verification={verification}
          lastLogin={lastLogin}
          isOnline={isOnline}
          personalNote={personalNote}
          registerDate={registerDate}
          published={published}
          isOpened={isProfileModalOpened}
          onToggle={setIsProfileModalOpened}
          isFavorite={isFavorite}
        />
      </>
    );
  }
);

export default ResultsItem;
