import React, { memo } from 'react';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import { FiX } from 'react-icons/fi';
import { truncate } from 'lodash';
import { renderFirstName } from '~helpers/common';

import VerificationBadge from '~components/verification/badge';
import Avatar from '~components/avatar';
import Button from '~components/button';
import Favorite from '~components/favorite';
import LastOnline from '~components/last-online';

import '../style.scss';

const HOUR = 3600;

const CustomerCard = memo(
  ({
    personId,
    className,
    duration,
    interval,
    address,
    days,
    firstName,
    profilePicture,
    verification,
    personalNote,
    showViewButton = false,
    showCloseButton = false,
    onClose = () => {},
    showFavorite = false,
    isFavorite,
    published,
    onView = () => {},
    lastLogin,
    isOnline,
  }) => {
    const intl = useIntl();

    const truncatedDescription = personalNote
      ? truncate(personalNote, {
          length: 90,
          separator: ' ',
        })
      : '-';

    const fName = renderFirstName(firstName);

    return (
      <div className={cx('c-card c-card--customer', { [className]: !!className })}>
        <div className={`c-card__inner ${!published ? 'c-card__inner_not_active' : ''}`}>
          <div className="c-card__header">
            <Avatar profilePicture={profilePicture} firstName={fName} className="c-card__photo" size="large" />
            {/* size="thumbnail" option is also there for smaller size picture*/}
            <div className="c-card__header-details">
              <div className="c-card__name l-truncate notranslate">
                {fName}
                <VerificationBadge isVerified />
              </div>
            </div>
            {showFavorite && (
              <Favorite
                personId={personId}
                accountType="client"
                isFavorite={isFavorite}
                mode="icon"
                className="c-card__favorite"
              />
            )}
            {showCloseButton && (
              <button type="button" className="c-card__close" onClick={onClose}>
                <FiX />
              </button>
            )}
          </div>
          <div className="c-card__content">
            {/* LAST ONLINE */}
            <div className="c-card__item">
              <div className="c-card__key">
                {intl.formatMessage({ id: 'components.card.last-online.label', defaultMessage: 'Last online' })}
              </div>
              <div className="c-card__value">
                <LastOnline lastOnline={lastLogin} isOnline={isOnline} isPublished={published} />
              </div>
            </div>
            {/* SERVICE */}
            <div className="c-card__item">
              <div className="c-card__key">
                {intl.formatMessage({ id: 'components.card.service', defaultMessage: 'Service' })}
              </div>
              <div className="c-card__value">
                {`${intl.formatMessage({ id: `interval.every-${interval}-weeks` })}, ${intl.formatMessage(
                  {
                    id: 'components.card.duration',
                    defaultMessage: '{duration, plural, =1 {# hour} other { # hours}}',
                  },
                  { duration: duration / HOUR }
                )}`}
              </div>
            </div>
            {/* DAYS */}
            <div className="c-card__item">
              <div className="c-card__key">
                {intl.formatMessage({ id: 'components.card.days.label', defaultMessage: 'Possible days' })}
              </div>
              <div className="c-card__value">
                {days.map(
                  (d, index) =>
                    `${intl.formatMessage({ id: `days.short.${d}` })}${index !== days.length - 1 ? ', ' : ''}`
                )}
              </div>
            </div>
            {/* LOCATION */}
            {/* <div className="c-card__item">
              <div className="c-card__key">
                {intl.formatMessage({ id: 'components.card.location.label', defaultMessage: 'Location' })}
              </div>
              <div className="c-card__value">{[address?.city, address?.country].filter(Boolean).join(', ')}</div>
            </div> */}
            {/* PERSONAL NOTE */}
            <div className="c-card__item c-card__item--last ">
              <div className="c-card__key">
                {intl.formatMessage({ id: 'components.card.personal-note.label', defaultMessage: 'Personal note' })}
              </div>
              <div className="c-card__value c-card__value--highlight">{truncatedDescription}</div>
            </div>
            {/* VIEW BUTTON */}
            {showViewButton && (
              <Button className="c-btn c-card__button" onClick={onView}>
                {intl.formatMessage({ id: 'components.card.view', defaultMessage: 'View' })}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default CustomerCard;
