import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';

import * as REST from '~services/rest';

import ServerContext from '~src/serverContext';

import NotFound from '~components/not-found';
import City from '~containers/city';

import FullLoader from '~components/loader/full-loader';

const Dynamic = () => {
  const intl = useIntl();
  const history = useHistory();

  const urlLocation = useLocation();
  const cache = useContext(ServerContext);

  const serverLocation = cache.get('serverLocation');
  const serverCityNotFound = cache.get('cityNotFound');

  const [locationDetails, setLocationDetails] = useState(serverLocation || null);
  const [isNotFound, setIsNotFound] = useState(serverCityNotFound || false);
  const [isValidatingSlug, setIsValidatingSlug] = useState(serverCityNotFound ? false : !serverLocation);

  useEffect(async () => {
    if (urlLocation.pathname.trim() === '/search') {
      history.replace('/');
      return;
    }

    if (serverLocation) {
      setTimeout(() => {
        cache.del('serverLocation');
        cache.del('cityNotFound');
      }, 0);
      
      setIsValidatingSlug(false);
      return;
    }

    if (!urlLocation.pathname) {
      setIsValidatingSlug(false);
      return;
    }

    setIsValidatingSlug(true);

    try {
      const slug = urlLocation.pathname.substring(1).toLowerCase().replaceAll(/\/$/g, '');

      if (slug.substring(0, 1) === '&' || slug.substring(0, 1) === '?') {
        history.replace('/');
        return;
      }

      const response = await REST.get({
        name: `locations`,
        params: {
          slug,
        },
        shouldThrowError: true,
      });

      const place = response.data;

      setLocationDetails({
        description: place.city,
        slug,
        locationId: place.place_id,
      });
      setIsNotFound(false);
    } catch (err) {
      console.log(err, 'Error while fetching cities');
      setIsNotFound(true);
    } finally {
      setIsValidatingSlug(false);
    }
  }, [urlLocation.pathname]);

  return (
    <>
      {!isValidatingSlug ? (
        isNotFound ? (
          <NotFound
            headline={intl.formatMessage({
              id: 'components.not-found.headline',
            })}
            content={intl.formatMessage({
              id: 'components.not-found.content',
            })}
          />
        ) : (
          <City location={locationDetails} />
        )
      ) : (
        <FullLoader />
      )}
    </>
  );
};

export default Dynamic;
