import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { useLayout } from '~hooks/useLayout';
import { useSeo } from '~hooks/useSeo';
import { useLocationDetails } from '~hooks/useLocationDetails';

import LocationContext from '~containers/workers/locationContext';
import WorkersList from '~containers/workers/list';
import FullLoader from '~components/loader/full-loader';
import Stars from '~components/stars';
// import { FaStar } from "react-icons/fa";


const City = ({ location }) => {
  const intl = useIntl();
  const urlLocation = useLocation();
  const [isMapView, setIsMapView] = useState(false);

  const { locationDetails, pricingDetails, isLoading, locationTouched, requestLocationByID } = useLocationDetails(
    location?.locationId,
    [urlLocation.pathname]
  );

  // Add effect to track location changes and manually trigger requestLocationByID
  useEffect(() => {
    if (location?.locationId) {
      requestLocationByID(location.locationId);
    }
  }, [location?.locationId]);

  useLayout({
    displayCities: !isMapView,
    displayNeighborhoods: !isMapView,
    displayFooter: !isMapView,
    displaySignIn: true,
    displayTopCTABar: true,
    logoToBottom: true,
    locationId: location?.locationId,
    isCityPage: true,
  }, [isMapView]);

  useSeo(
    intl.formatMessage(
      {
        id: 'containers.city.seo.title',
      },
      { city: location?.description }
    )
  );

  const handleMapViewChange = (isShowingMap) => {
    // console.log("triggered ", isShowingMap)
    setIsMapView(isShowingMap);
  };

  return (
    <LocationContext.Provider
      value={{
        locationDetails,
        pricingDetails,
        initialSearchLocationId: location?.locationId,
        isLoadingLocation: isLoading,
        locationTouched,
        requestLocationByID,
      }}
    >
      <section className="c-page l-container c-page--matches c-page-loder-hight">
        {locationDetails ? (
          <>
          <a
            className="c-star__fc-link"
            href="https://www.feedbackcompany.com/nl-nl/reviews/juan-pescador/"
            rel="noopener noreferrer nofollow"
            aria-label="Feedback Company"
            target="_blank"
          >
            <Stars value={4} />
          </a>
          <WorkersList isCityPage={true} onMapViewChange={handleMapViewChange} />
          </>
        ) : (
          null
          // <FullLoader />
        )}
      </section>
    </LocationContext.Provider>
  );
};

export default City;