import React, { memo } from 'react';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import { FiX } from 'react-icons/fi';
import { truncate } from 'lodash';
import { renderFirstName } from '~helpers/common';

import VerificationBadge from '~components/verification/badge';
import Avatar from '~components/avatar';
import Button from '~components/button';
import Favorite from '~components/favorite';
import LastOnline from '~components/last-online';

import '../style.scss';

const HOUR = 3600;

const CustomerCardMobile = memo(
  ({
    personId,
    className,
    duration,
    interval,
    address,
    days,
    firstName,
    profilePicture,
    verification,
    personalNote,
    showViewButton = false,
    showCloseButton = false,
    onClose = () => {},
    showFavorite = false,
    isFavorite,
    published,
    onView = () => {},
    lastLogin,
    isOnline,
  }) => {
    const intl = useIntl();

    const truncatedDescription = personalNote
      ? truncate(personalNote, {
          length: 90,
          separator: ' ',
        })
      : '-';

    const fName = renderFirstName(firstName);

    return (
      <div className={cx('c-card c-card__mob_list c-card--customer', { [className]: !!className })}>
        <div className={`c-card__inner ${!published ? 'c-card__inner_not_active' : ''}`}>
          <div className="c-card__header">
            <Avatar profilePicture={profilePicture} firstName={fName} className="c-card__photo" size="large" />
            {/* size="thumbnail" option is also there for smaller size picture*/}
            <div className="c-card__value c-card__value--highlight">{truncatedDescription}</div>
            
            {showCloseButton && (
              <button type="button" className="c-card__close" onClick={onClose}>
                <FiX />
              </button>
            )}
          </div>
          <div className="c-card__content">
            <div className="c-card__mob__name">
              <div className="c-card__name l-truncate notranslate">
                {fName}
                <VerificationBadge isVerified />
              </div>
              {/* LAST ONLINE */}
              <div className="c-card__item">
                <div className="c-card__key">
                  {intl.formatMessage({ id: 'components.card.last-online.label', defaultMessage: 'Last online' })}
                  <LastOnline lastOnline={lastLogin} isOnline={isOnline} isPublished={published} />
                </div>
              </div>
            </div>
            {/* VIEW BUTTON */}
            {showViewButton && (
              <Button className="c-btn c-card__button" onClick={onView}>
                {intl.formatMessage({ id: 'components.card.mobile-view', defaultMessage: 'View' })}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default CustomerCardMobile;
