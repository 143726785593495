import React from 'react';
import cx from 'classnames';
import { FiChevronDown, FiArrowDown, FiArrowUp } from 'react-icons/fi';
import { useIntl } from 'react-intl';

import Dropdown from '~components/dropdown';
import Modal from '~components/modal';
import { SkeletonSimple } from '~components/skeleton';
import { useIsMobile } from '~hooks/common';

const Sort = ({ 
  value, 
  onChange = () => {}, 
  order, 
  className, 
  isLoading,
  isVisible = false,
  onVisibleChange = () => {} 
}) => {
  const intl = useIntl();
  const isMobile = useIsMobile();

  const handleChange = (val) => {
    onVisibleChange(false);
    onChange(val);
  };

  if (isLoading) {
    return <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-skeleton-simple__matches-sort" />;
  }

  if (isMobile) {
    return (
      <>
        <div
          className={cx('c-matches__sort', {
            [className]: !!className,
          })}
        >
          <button type="button" className="c-listing__sort-toggle" onClick={() => onVisibleChange(true)}>
            <span>{value.label}</span>
            <FiChevronDown className="c-listing__sort-chevron-down" />
          </button>
        </div>

        <Modal.Wrapper 
          isOpened={isVisible} 
          onClose={() => onVisibleChange(false)}
          className="c-modal--matches-sort"
        >
          <Modal.Header>
            <h3 className="l-headline--tertiary">
              {intl.formatMessage({ id: 'containers.workers.list.sort.label', defaultMessage: 'Sort by' })}
            </h3>
          </Modal.Header>
          <Modal.Content>
            <div className="c-vertical-nav">
              {order.map((o) => (
                <button
                  type="button"
                  className="c-vertical-nav__item c-btn-text"
                  key={`${o.value}-${o.direction}`}
                  onClick={() => handleChange(o)}
                >
                  {o.label}
                  {o.arrowDown && <FiArrowDown className="c-listing__sort-item-arrow" />}
                  {o.arrowUp && <FiArrowUp className="c-listing__sort-item-arrow" />}
                </button>
              ))}
            </div>
          </Modal.Content>
        </Modal.Wrapper>
      </>
    );
  }

  return (
    <div
      className={cx('c-matches__sort', {
        [className]: !!className,
      })}
    >
      <span className="c-matches__sort-label">
        {intl.formatMessage({ id: 'containers.workers.list.sort.label', defaultMessage: 'Sort by' })}
        {':'}
      </span>
      <Dropdown.Container
        value={value.value}
        placement="bottom-end"
        visible={isVisible}
        onVisibleChange={onVisibleChange}
        optionsClassName="c-context-menu c-header__context-menu"
      >
        <Dropdown.Toggle>
          <button type="button" className="c-listing__sort-toggle">
            <span>{value.label}</span>
            <FiChevronDown
              className={cx('c-listing__sort-chevron-down', {
                active: isVisible,
              })}
            />
          </button>
        </Dropdown.Toggle>
        <Dropdown.Content>
          <div className="c-vertical-nav c-context-menu__nav">
            {order.map((o) => (
              <button
                type="button"
                className="c-vertical-nav__item c-context-menu__nav__item c-btn-text"
                key={`${o.value}-${o.direction}`}
                onClick={() => handleChange(o)}
              >
                {o.label}
                {o.arrowDown && <FiArrowDown className="c-listing__sort-item-arrow" />}
                {o.arrowUp && <FiArrowUp className="c-listing__sort-item-arrow" />}
              </button>
            ))}
          </div>
        </Dropdown.Content>
      </Dropdown.Container>
    </div>
  );
};

export default Sort;
