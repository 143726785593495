import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { CiFilter } from "react-icons/ci";
import { RxPinTop } from "react-icons/rx";
import { TbArrowsSort } from "react-icons/tb";
import { TfiAnnouncement } from "react-icons/tfi";
import { PiMapPin } from "react-icons/pi";
import { FaListUl } from "react-icons/fa";
import Loader from '~components/loader';
import { UserContext } from '~contexts/user';
import queryString from 'query-string';
import { getValidatedQueryString } from '~helpers/queryUtils';
// import './style.scss';

const BottomNav = ({ 
  onFilterClick,
  onSortClick,
  onMapClick,
  isMapActive,
  isMapDisabled,
  isLoading,
  locationId
}) => {
    const history = useHistory();
    const intl = useIntl();
    const location = useLocation();
    const { user } = useContext(UserContext);

    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
      setIsClient(true);
    }, []);

    const handleClick = (e, callback) => {
        const circle = document.createElement('div');
        circle.classList.add('click-circle');
        
        const rect = e.currentTarget.getBoundingClientRect();
        circle.style.left = `${e.clientX - rect.left}px`;
        circle.style.top = `${e.clientY - rect.top}px`;
        
        e.currentTarget.appendChild(circle);
        
        setTimeout(() => {
            circle.remove();
        }, 1000);

        if (callback) {
            callback();
        }
    };

    const scrollToTop = () => {
        if (isClient) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const handleAdClick = () => {
      const availableLocationId = user?.accountType === 'worker' 
        ? user?.operations?.address?.place_id
        : user?.wish?.address?.place_id || null;

      if (!availableLocationId) {
        history.push(`/intake/client?locationId=${locationId}&showFields=true`);
        // const queryParams = queryString.parse(getValidatedQueryString(location.search));
        // const parsedLocationId = queryParams?.locationId || null;
        
        // if (parsedLocationId) {
        //   history.push(`/intake/client?locationId=${parsedLocationId}&showFields=true`);
        // } else {
        //   history.push('/intake/client');
        // }
      } else {
        if (user?.accountType === 'worker') {
          history.push('/edit-offer');
        } else {
          history.push('/edit-wish');
        }
      }
    };

    return (
      <nav className="bottom-nav">

        {isLoading ? (
          <div className="nav-item disabled">
            <div className="icon-loader">
              <Loader size="small" />
            </div>
            <span>{intl.formatMessage({ 
              id: 'components.bottom-bar.filter',
              defaultMessage: 'Filter'
            })}</span>
          </div>
        ) : (
          <div className="nav-item" onClick={(e) => handleClick(e, onFilterClick)}>
            <CiFilter />
            <span>{intl.formatMessage({ 
              id: 'components.bottom-bar.filter',
              defaultMessage: 'Filter'
            })}</span>
          </div>
        )}

        

        <div className="nav-item" onClick={(e) => handleClick(e, scrollToTop)}>
          <RxPinTop />         
          <span>{intl.formatMessage({ 
            id: 'components.bottom-bar.top',
            defaultMessage: 'Top'
          })}</span>
        </div>
        {isLoading ? (
          <div className="nav-item disabled">
            <div className="icon-loader">
              <Loader size="small" />
            </div>
            <span>{intl.formatMessage({ 
              id: 'components.bottom-bar.sort',
              defaultMessage: 'Sort'
            })}</span>
          </div>
        ) : (
          <div className="nav-item" onClick={(e) => handleClick(e, onSortClick)}>
            <TbArrowsSort />
            <span>{intl.formatMessage({ 
              id: 'components.bottom-bar.sort',
              defaultMessage: 'Sort'
            })}</span>
          </div>
        )}
        <div className="nav-item" onClick={(e) => handleClick(e, handleAdClick)}>
          <TfiAnnouncement className="icon-ad" />
          <span>{intl.formatMessage({ 
            id: 'components.bottom-bar.ad',
            defaultMessage: 'Ad'
          })}</span>
        </div>
        <div 
          className={`nav-item ${isMapActive ? 'active' : ''} ${
            isMapDisabled || isLoading ? 'disabled' : ''
          }`} 
          onClick={(e) => !isMapDisabled && !isLoading && handleClick(e, onMapClick)}
        >
          {isLoading ? (
            <>
              <div className="icon-loader">
                <Loader size="small" />
              </div>
            </>
          ) : (
            <>
              {isMapActive ? (
                <>
                  <FaListUl />
                  <span>{intl.formatMessage({ 
                    id: 'components.bottom-bar.list',
                    defaultMessage: 'List'
                  })}</span>
                </>
              ) : (
                <>
                  <PiMapPin className="icon-map" />
                  <span>{intl.formatMessage({ 
                    id: 'components.bottom-bar.map',
                    defaultMessage: 'Map'
                  })}</span>
                </>
              )}
            </>
          )}
        </div>
      </nav>
    );
};

export default BottomNav;

