import React, { useState, useContext } from 'react';

import WorkerCard from '~components/card/worker';
import ProfileModal from '~containers/profile/modal/worker';
import ServerContext from '~src/serverContext';
import WorkerCardMobile from '~components/card/worker/indexMobile';
// import { useIsMobile } from '~hooks/common';

const ResultsItem = ({
  personId,
  locationId,
  score,
  price,
  address,
  experience,
  firstName,
  profilePicture,
  verification,
  lastLogin,
  isOnline,
  registerDate,
  personalNote,
  days,
  published,
  showFavorite,
  isFavorite,
  isCityPage=false,
  cityName=false,
  citySlug=false,
  onFavorite = () => {},
  showCloseButton,
  onClose,
}) => {
  const [isProfileModalOpened, setIsProfileModalOpened] = useState(false);
  const cache = useContext(ServerContext);
  const isMobile = cache.get('isMobile');
  // console.log('ismobile ', isMobile)
  // const isMobile = useIsMobile();
  
  return (
    <>
      {isMobile ? (
        <WorkerCardMobile
          personId={personId}
          locationId={locationId}
          score={score}
          price={price}
          address={address}
          experience={experience}
          firstName={firstName}
          profilePicture={profilePicture}
          verification={verification}
          lastLogin={lastLogin}
          isOnline={isOnline}
          personalNote={personalNote}
          days={days}
          published={published}
          registerDate={registerDate}
          showViewButton
          onView={setIsProfileModalOpened}
          showFavorite={showFavorite}
          isFavorite={isFavorite}
          onFavorite={onFavorite}
          showCloseButton={showCloseButton}
          onClose={onClose}
        />
      ) : (
        <WorkerCard
          personId={personId}
          locationId={locationId}
          score={score}
          price={price}
          address={address}
          experience={experience}
          firstName={firstName}
          profilePicture={profilePicture}
          verification={verification}
          lastLogin={lastLogin}
          isOnline={isOnline}
          personalNote={personalNote}
          days={days}
          published={published}
          isCityPage={isCityPage}
          cityName={cityName}
          citySlug={citySlug}
          registerDate={registerDate}
          showViewButton
          onView={setIsProfileModalOpened}
          showFavorite={showFavorite}
          isFavorite={isFavorite}
          onFavorite={onFavorite}
          showCloseButton={showCloseButton}
          onClose={onClose}
        />
      )}
  
      <ProfileModal
        personId={personId}
        locationId={locationId}
        score={score}
        price={price}
        address={address}
        experience={experience}
        firstName={firstName}
        profilePicture={profilePicture}
        verification={verification}
        lastLogin={lastLogin}
        isOnline={isOnline}
        personalNote={personalNote}
        registerDate={registerDate}
        published={published}
        isOpened={isProfileModalOpened}
        onToggle={setIsProfileModalOpened}
        isFavorite={isFavorite}
        onFavorite={onFavorite}
      />
    </>
  );  
};

export default ResultsItem;
